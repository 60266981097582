import React from 'react';
import { useState } from 'react';

const VideoFolower = ({ showHide }) => {
    const [mousex, setMousex] = useState();
    const [mousey, setMouseY] = useState();
    const rect = document.body.getBoundingClientRect();
    window.addEventListener('mousemove', setMousePosition);
    function setMousePosition(e) {
        setMousex(e.clientX - rect.left);
        setMouseY(e.clientY - rect.top);
    }

    return (
        <div onMouseMove={setMousePosition} >
            <div
                style={{
                    width: '200px',
                    height: '200px',
                    backgroundImage: 'url(images/soundplay.svg)',
                    backgroundSize: 'cover',
                    cursor: 'pointer',
                    zIndex: 9,
                    position: 'absolute',
                    display: `${showHide}`,
                    transform: "translate(-50%, -50%)",
                    left: `${mousex}px`,
                    top: `${mousey}px`,
                    borderRadius: "50%"
                }}

                class="custom-cursor"
            >   
            </div>
        </div>
    );
};

export default VideoFolower;