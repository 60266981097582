import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import NavFolower from '../NavFolower/NavFolower';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';

const NavBar = () => {
    const [linkFolower, setLinkFolower] = useState("none")
    const [isArrow, setIsArrow] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    return (
        <div className='pt-5 main-div '>
            <div className=' relative'>
                <div className={`flex items-center justify-between absolute top-0 left-0 w-full py-3  bg-transparent $ "h-[72px] rounded-full duration-500"} ${showMenu ? "border-[1px] border-transparent duration-500" : "border-[1px] backdrop-blur-[34px] duration-500"} border-[#1315191A] px-5 z-50 `}>
                    <Link to="/">
                        <div className="nav_left_content  flex items-center justify-start h-full ">
                            <img src="https://i.ibb.co/SX7fN07/Nav-logo.png" alt="logo" />
                            {/* <span className="ml-[7px] text-[#131519] font-bold text-[15.91px] md:text-[19.68px]  font-[Inter] !important">
                                Batin Studio
                            </span> */}
                        </div>
                    </Link>
                    <div className=" flex  items-center justify-between">
                        <div className={`hidden lg:block ${showMenu ? "opacity-0" : "opacity-100"} `}>
                            <ul className={'flex justify-end gap-10 w-[600px] h-full items-center opacity-100 duration-300  '}>
                                <li className="desktop hover:border-b hover:border-[#b8b6b6] duration-300">
                                    <Link className='font-[Inter]' to="/portfolio">Portfolio</Link>
                                </li>
                                <li className="desktop hover:border-b hover:border-[#b8b6b6] duration-300">
                                    <Link className='font-[Inter]' to="/about">About</Link>
                                </li>
                                <li className="desktop hover:border-b hover:border-[#b8b6b6] duration-300">
                                    <Link className='font-[Inter]' to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                        <div onMouseEnter={() => setIsArrow(!isArrow)}
                            onMouseLeave={() => setIsArrow(!isArrow)}
                            onClick={() => setShowMenu(!showMenu)}
                            className="ml-10 my-auto w-[100px] menuBtn border-[2px] rounded-full py-2 px-4 hover:bg-black hover:text-white duration-300">
                            <button className=" flex justify-between items-center duration-300 font-[Inter] ">
                                Menu
                                {
                                    isArrow ?
                                        <span className='duration-300'>
                                            {
                                                showMenu ? <AiOutlineArrowUp className='ms-1 text-xl duration-300'></AiOutlineArrowUp> : <AiOutlineArrowDown className='ms-1 text-xl duration-300'></AiOutlineArrowDown>
                                            }
                                        </span>
                                        :
                                        <i className="fa-solid mt-1 ml-2 fa-1x fa-bars my-auto"></i>
                                }

                            </button>
                        </div>
                    </div>
                </div>

                <div className={` ${showMenu ? "high-div-add-style" : "high-div-removeStyle"} absolute top-0 left-0 w-full z-40 backdrop-blur-[34px]  ${showMenu ? "addHeight" : "removeGeight"} flex items-start justify-center`}>
                    <div className={`text-black  mt-28 ${showMenu ? "block duration-500" : "hidden"}`}>
                        <div className='flex flex-col-reverse md:flex-row mx-auto md:gap-40 md:pt-8  duration-300'>

                            <ul className={`flex flex-col md:gap-4 list-none mt-16 md:mt-0 ${showMenu ? "opacity-100 duration-500" : "opacity-10 duration-500"}`}>
                                <li className='font-[Inter] nav-md-font text-center md:text-left'>
                                    Get in touch
                                </li>
                                <span className='flex flex-row  md:flex-col mt-4 md:mt-0 '>
                                    <li className='font-[Inter] nav-sm-font mx-2 md:mx-0 mb-2 md:pt-[32px] '>
                                        <a target="_blank" className='border-b-[2px] border-[transparent] hover:border-[#131519CC] ' href="https://www.behance.net/batinstudio">Behance</a>
                                    </li>
                                    <li className='font-[Inter] nav-sm-font mx-2 md:mx-0 mb-2'>
                                        <a target="_blank" className='border-b-[2px] border-[transparent] hover:border-[#131519CC] '
                                            href="https://www.linkedin.com/company/batinstudio" >LinkedIn</a>
                                    </li>
                                    <li className='font-[Inter] nav-sm-font mx-2 md:mx-0 mb-2'>
                                        <a
                                            target="_blank" className='border-b-[2px] border-[transparent] hover:border-[#131519CC] '
                                            href="https://www.instagram.com/batin_studio/" >Instagram</a>
                                    </li>
                                    <li className='font-[Inter] nav-sm-font mx-2 md:mx-0'>
                                        <a target="_blank" className='border-b-[2px] border-[transparent] hover:border-[#131519CC] ' href="https://twitter.com/batinstudio">Twitter</a>
                                    </li>
                                </span>
                                <li className='font-[Inter] pt-[5px] md:pt-[32px] pb-2 md:pb-0 text-center md:text-left'>
                                    <a href="mailto:hello@batinstudio.com">hello@batinstudio.com</a>
                                </li>
                            </ul>

                            <ul onMouseEnter={() => setLinkFolower("block")} onMouseLeave={() => setLinkFolower("none")} className='  cursor-pointer-style flex flex-col gap-[16px] list-none -mt-10 md:mt-0 '>
                                <span className='hidden lg:block'>
                                    <NavFolower linkFolower={linkFolower} ></NavFolower>
                                </span>
                                <li className='hover:text-white duration-500 text-center md:text-left md:-mt-4'  >
                                    <Link className='nav-md-font font-[Inter] hover:cursor-default '>Menu </Link>
                                </li>
                                <li className='nav-lg-font hover:text-white duration-500 text-center md:text-left'>
                                    <Link to="/portfolio" className=' text-[40px] md:text-[48px] font-[NewYork]  '>Portfolio</Link>
                                </li>
                                <li className='nav-lg-font hover:text-white duration-500 text-center md:text-left'>
                                    <Link to="/about" className=' text-[40px] md:text-[48px] font-[NewYork]  ' >About</Link>
                                </li>
                                <li className='nav-lg-font hover:text-white duration-500 text-center md:text-left'>
                                    <Link to="/contact" className=' text-[40px] md:text-[48px] font-[NewYork]  '>Contact</Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavBar;