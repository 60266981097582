import React, { useState } from "react";
import arrow from "../components/assets/images/Arrow.png";
import { Link } from "react-router-dom";
import "../style/LatsContact.css";


const Lets = () => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div className="lets-style">
      <section className="section mt-[120px]">
        <div
        onMouseEnter={() => setIsHover(!isHover)}
        onMouseLeave={() => setIsHover(!isHover)}
          className="rounded-t-full rounded-l-full mx-auto w-[340px] h-[340px]
                    sm:w-[400px] 
                    sm:h-[400px] 
                    relative
                    duration-300
                    lats-contact-style
                    "
        >
          <div className="text-[35px] sm:text-[40px] text-center pt-24 px-10 first-div " >
            Let's do something cool Together
          </div>

          <Link to="/contact">
          <div className="lats-contact-child bg-white absolute bottom-4 right-4 text-black"> 
            <p> Let's Connect

             </p>
            <img className="ml-2 rotate-180 my-auto " src={arrow} alt="" />
          </div>
            </Link>          
        </div>        
      </section>

      
    </div>
  );
};

export default Lets;

