import BatinLogo from '../components/assets/images/logo.svg';
import Insta from '../components/assets/images/insta.png';
import GitHub from '../components/assets/images/github.png';
import LinkedIn from '../components/assets/images/linkedin.png';
import { PiArrowBendDoubleUpRightBold } from 'react-icons/pi';
import '../style/NineSec.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='nineSec-main-div '>

      {/* <section className="mt-40 w-[90%] pb-5 h-[147px] mx-auto flex justify-between flex-col px-6 rounded-tl-[40px] rounded-tr-[40px] bg-black">
        <div className=" w-[90%] h-[147px] footer mx-auto flex justify-between px-6 rounded-tl-[40px] rounded-tr-[40px] bg-black">
          <div className="w-[150px] fwid flex pt-10 justify-start">
            <div className=" border-r-[1px] border-rr flex justify-start items-center pr-5 h-[20px] border-gray-300">
              <img
                className="w-[15px] h-[28px] mr-3"
                src="https://i.ibb.co/nR4Vt4P/footericon.png"
                alt="footericon"
              />
              <span className="text-white font-[Inter]">Batin Studio</span>
            </div>
          </div>

          <div className="w-[400px] fwid linksk flex justify-start pt-10">
            <a href="/" className="text-white mx-2  font-sans ">
              Home
            </a>
            <a href="/" className="text-white mx-2  font-sans ">
              About
            </a>
            <a href="/" className="text-white mx-2  font-sans ">
              News
            </a>
            <a href="/" className="text-white mx-2  font-sans ">
              Contact
            </a>
          </div>

          <div className="social-service-div w-[400px] fwid flex justify-end pt-10"> 

          <span className="">
              <a
                target="_blank"
                href="https://www.instagram.com/batin_studio/"
                className="font-[Inter]"
              >
                <img
                  src="https://i.ibb.co/yNqdg5z/insta.png"
                  className="w-4 h-4 mr-2"
                  alt="insta"
                />
                Instagram <PiArrowBendDoubleUpRightBold className="ms-[8px]"></PiArrowBendDoubleUpRightBold>
              </a>
            </span>

           <span>
           <a
              target="_blank"
              href="https://github.com/Batin-Studio"
              className=""
            >
              <img
                src="https://i.ibb.co/2ZJ3vLb/github.png"
                className="w-4 h-4 mr-2 font-[Inter]"
                alt="insta"
              />
              Github <PiArrowBendDoubleUpRightBold className="ms-[8px]"></PiArrowBendDoubleUpRightBold>
            </a>
           </span>

           <span>
           <a
              target="_blank"
              href="https://www.linkedin.com/company/batinstudio" // Corrected href attribute
              className="font-[Inter]"
            >
              <img
                src="https://i.ibb.co/gyMmL98/linkedin.png"
                className="w-4 h-4 mr-2"
                alt="LinkedIn"
              />
              LinkedIn <PiArrowBendDoubleUpRightBold className="ms-[8px]"></PiArrowBendDoubleUpRightBold>
            </a>

           </span>

          </div>


        </div>
        <p className="mt-10 text-gray-400 text-xs font-[Inter]  text-center">
          © Batin Studio 2023 | All rights reserved.
        </p>


      </section> */}

      <footer className="mt-40 w-[95%] lg:max-w-[1440px] mx-auto px-[40px] py-[12px]  rounded-tl-[40px] rounded-tr-[40px] bg-black">

        <div className="flex justify-between flex-col lg:flex-row pt-10 px-0 md:px-[75px] -mt-12 md:-mt-10 lg:mt-0 ">
          <div className="flex flex-col md:flex-row justify-between lg:justify-start md:pt-5 lg:pt-0 ">

            <div className=" w-52 mx-auto md:ms-0">
              <div className=" border-r-[1px] border-rr flex justify-start items-center pr-5 mt-5 md:mt-0 h-[20px] border-gray-300">
                <img
                  className="w-[15px] h-[28px] mr-3"
                  src="https://i.ibb.co/nR4Vt4P/footericon.png"
                  alt="footericon"
                />
                <span className="text-white font-[Inter]">Batin Studio</span>
              </div>
            </div>

            <div className="  w-full flex justify-center  md:justify-evenly py-5 md:py-0 ">
              <Link to="/" className="text-white mx-3 md:mx-2 text-[14px]  font-sans ">
                Home
              </Link>
              <Link to="/about" className="text-white mx-3 md:mx-2 text-[14px]  font-sans ">
                About
              </Link>
              <Link to="/portfolio" className="text-white mx-3 md:mx-2 text-[14px]  font-sans ">
                Portfolio
              </Link>
              <Link to="/contact" className="text-white mx-3 md:mx-2 text-[14px]  font-sans ">
                Contact
              </Link>
            </div>


          </div>


          <div className="social-service-div  flex justify-center lg:justify-end md:pt-10 lg:pt-0 ">


            <span className="hidden md:block">
              <a
                target="_blank"
                href="https://www.instagram.com/batin_studio/"
                className="font-[Inter]"
              >
                <img
                  src="https://i.ibb.co/yNqdg5z/insta.png"
                  className="w-4 h-4 mr-2"
                  alt="insta"
                />
                Instagram <PiArrowBendDoubleUpRightBold className="ms-[8px]"></PiArrowBendDoubleUpRightBold>
              </a>
            </span>

            <span className="hidden md:block" >
              <a
                target="_blank"
                href="https://github.com/Batin-Studio"
                className="font-[Inter]"
              >
                <img
                  src="https://i.ibb.co/2ZJ3vLb/github.png"
                  className="w-4 h-4 mr-2 "
                  alt="insta"
                />
                Github <PiArrowBendDoubleUpRightBold className="ms-[8px]"></PiArrowBendDoubleUpRightBold>
              </a>
            </span>

            <span className="hidden md:block">
              <a
                target="_blank"
                href="https://www.linkedin.com/company/batinstudio"
                className="font-[Inter]"
              >
                <img
                  src="https://i.ibb.co/gyMmL98/linkedin.png"
                  className="w-4 h-4 mr-2"
                  alt="LinkedIn"
                />
                LinkedIn <PiArrowBendDoubleUpRightBold className="ms-[8px]"></PiArrowBendDoubleUpRightBold>
              </a>

            </span>

            <span className="block md:hidden flex w-32">

              <a
                target="_blank"
                href="https://www.instagram.com/batin_studio/"
              >
                <img
                  src="https://i.ibb.co/yNqdg5z/insta.png"
                  className="w-6 h-6 mr-2"
                  alt="insta"
                />
              </a>

              <a
                target="_blank"
                href="https://github.com/Batin-Studio"
              >
                <img
                  src="https://i.ibb.co/2ZJ3vLb/github.png"
                  className="w-6 h-6 mr-2 "
                  alt="insta" />
              </a>

              <a
                target="_blank"
                href="https://www.linkedin.com/company/batinstudio"
              >
                <img
                  src="https://i.ibb.co/gyMmL98/linkedin.png"
                  className="w-6 h-6 mr-2"
                  alt="LinkedIn" />
              </a>
            </span>
          </div>
        </div>

        <p className="text-gray-400 text-xs font-[Inter] w-full  text-center lg:pt-10 mt-6 lg:mt-0">
          © Batin Studio 2023 | All rights reserve <Link className="-ms-1" to={"/admin"}>d</Link>.
        </p>

      </footer>


    </div>
  );
};

export default Footer;
