import React, { useState } from 'react';
import '../style/seven.css'


// import Swiper styles


const SevenSec = () => {
  const block = '0px';
  const dnone = '-35px';
  const point = '';
  const pointOut = '-1500px';
  const border = " 2px solid #131519CC ";
  const borderMove = "1px solid #1315191A";
  // const borderMove = "1px solid gray";

  const [FisBorder, setFborder] = useState(borderMove)
  const [SisBorder, setSorder] = useState(borderMove)
  const [TisBorder, setTborder] = useState(borderMove)
  const [FoIsBorder, setFoorder] = useState(border)

  const [fLink, setFlink] = useState(block);
  const [sLink, setSlink] = useState(dnone);
  const [tLink, setTlink] = useState(dnone);
  const [gLink, setGlink] = useState(dnone);

  const [fImg, setFimg] = useState(point);
  const [sImg, setSimg] = useState(point);
  const [tImg, setTimg] = useState(point);

  const [gImg, setGimg] = useState(point);

  const [currentIndex, setCurrentIndex] = useState(1);

  const eventsToTrigger = [
    () => firstLink(),
    () => secondLink(),
    () => thirdLink(),
    () => fourthLink(),
    // Add more events as needed
  ];

  const handleNextClick = () => {
    if (currentIndex < eventsToTrigger.length) {
      const currentEvent = eventsToTrigger[currentIndex];
      currentEvent();
      setCurrentIndex(currentIndex + 1);
    }
    return;
  };

  const handlePrevtClick = () => {
    if (currentIndex > 1) {
      setCurrentIndex(currentIndex - 1);
      const currentEvent = eventsToTrigger[currentIndex - 2]; // Subtract 2 to get the previous event
      currentEvent();
    }
  };

  const firstLink = () => {

    setFborder(borderMove)
    setSorder(borderMove)
    setTborder(borderMove)
    setFoorder(border)

    setFlink(block);
    setSlink(dnone);
    setTlink(dnone);
    setGlink(dnone);
    setFimg(point);
    setSimg(point);
    setTimg(point);
    setGimg(point);
    setCurrentIndex(1);
  };
  const secondLink = () => {

    setFborder(borderMove)
    setSorder(borderMove)
    setTborder(border)
    setFoorder(borderMove)

    setFlink(dnone);
    setSlink(block);
    setTlink(dnone);
    setGlink(dnone);

    setFimg(pointOut);
    setSimg(point);
    setTimg(point);
    setGimg(point);
    setCurrentIndex(2);
  };
  const thirdLink = () => {
    setFborder(borderMove)
    setSorder(border)
    setTborder(borderMove)
    setFoorder(borderMove)

    setTlink(block);
    setSlink(dnone);
    setFlink(dnone);
    setGlink(dnone);
    setTimg(pointOut);
    setSimg(pointOut);
    setTimg(point);
    setGimg(point);
    setCurrentIndex(3);
  };

  const fourthLink = () => {
    setFborder(border)
    setSorder(borderMove)
    setTborder(borderMove)
    setFoorder(borderMove)

    setTlink(dnone);
    setSlink(dnone);
    setFlink(dnone);
    setGlink(block);
    setTimg(pointOut);
    setSimg(pointOut);
    setFimg(pointOut);
    setGimg(pointOut);
    setCurrentIndex(4);
  };


  return (
    <div className='seven-main-div -mt-36 md:mt-0'>

      <div className="mt-[150px] pt-[90px] md:pt-[0px] lg:py-[90px] ">
        <p className="text-left font-[inter] mobile pl-5 text-[20px]  2px solid gray leading-[20px] font-[400] ">
          Work Process
        </p>
        <h2
          className="mt-[26px] mobile text-left  leading-[50px] pl-5 font-[400]"
          style={{ textAlign: 'left', fontSize: '50px' }}
        >
          Wondering, <br /> how we work?
        </h2>

        <div className="w-[90%] wonderWrapper  mx-auto flex md:flex-col-reverse lg:flex-row justify-between items-center gap-5">

          <div className="left_fourth relative h-[300px] w-[50%] md:w-[80%] lg:w-[50%]  ">

            {/* <div className="w-[95%] bg-whitee border-[1px] transition-all duration-1000 top-0 -left-4 md:left-0 p-5 h-[248px] flex justify-center flex-col rounded-2xl absolute mx-auto" style={{ border: FisBorder }}> */}
            <div className="w-[95%] bg-whitee border-[1px] transition-all duration-1000 top-0 md:top-4 lg:top-0 -left-6 md:left-0 p-5 h-[219px] md:h[228px] flex justify-center flex-col rounded-2xl absolute mx-auto" style={{ border: FisBorder }}>
              <img
                className="w-8 h-8"
                src="images/HandsClapping.png"
                alt="brief icon"
              />
              <h4 className="font-black mt-3 font-[Inter] font-semibold text-[20px] mb-4">
                Get result
              </h4>
              <p className="font-[Inter]">
                Receive the perfect quality of your services on time & leave us a review if you like.
              </p>
            </div>


            {/* <div className="w-[95%] bg-whitee border-[1px] transition-all  duration-1000 -ml-1 -mt-4 md:ml-6 md:-mt-3 lg:ml-3 lg:mt-3  p-5 h-[219px] md:h[228px] flex justify-center  flex-col rounded-2xl  absolute mx-auto " */}
            <div className="w-[95%] bg-whitee border-[1px] transition-all  duration-1000 -ml-[25px] -mt-14 md:ml-6 md:-mt-4 lg:ml-3 lg:mt-3  p-5 h-[219px] md:h[228px] flex justify-center  flex-col rounded-2xl  absolute mx-auto "
              style={{ marginLeft: tImg, border: SisBorder }}
            >
              <img
                className="w-8 h-8"
                src="images/CircleWavyCheck.png"
                alt="breif icon"
              />
              <h4 className="font-black mt-3 font-[Inter] font-semibold text-[20px] mb-4">Approve </h4>
              <p className="font-[Inter]">
                Approve the draft version of the services you like and want us to deliver to you. Nice and simple.
              </p>
            </div>

            {/* <div className=" w-[95%]  bg-whitee border-[1px] transition-all duration-1000  mt-6 ml-6  md:mt-6 md:ml-12 lg:mt-6 lg:ml-6 p-5 h-[219px] md:h[228px] flex justify-center  flex-col rounded-2xl  absolute mx-auto " */}
            <div className=" w-[95%]  bg-whitee border-[1px] transition-all duration-1000  -mt-6 ml-1 md:mt-5 md:ml-12 lg:mt-6 lg:ml-6 p-5 h-[219px] md:h[228px] flex justify-center  flex-col rounded-2xl  absolute mx-auto "
              style={{ marginLeft: sImg, border: TisBorder }}
            >
              <img
                className="w-8 h-8"
                src="images/ChatsCircle.png"
                alt="breif icon"
              />
              <h4 className="font-black mt-3 font-[Inter] font-semibold text-[20px]  mb-4"> Stay in toush  </h4>
              <p className="font-[Inter]">
                Review preliminary results and leave your feedback for us to continue or make corrections
              </p>
            </div>

            <div className="w-[95%]  border-[1px] top-3 transition-all duration-1000 bg-whitee mt-8 ml-10 md:ml-20 md:mt-14 lg:ml-9 lg:mt-6  p-5 h-[219px] md:h[228px] flex justify-center  flex-col rounded-2xl  absolute mx-auto "
              style={{ marginLeft: fImg, border: FoIsBorder }}
            >
              <img
                className="w-8 h-8"
                src="images/Notebook.png"
                alt="breif icon"
              />
              <h4 className="font-black mt-3 font-[Inter] font-semibold text-[20px]  mb-4">Send brief </h4>
              <p className="font-[Inter] text-[16px]">
                Send us a complete brief along with documents & requirements to
                estimate the project.
              </p>
            </div>


            <div className="absolute -bottom-14  w-full flex justify-center p-1 items-center ">
              <div className="w-[180px] -mt-10 flex justify-center items-center  ">

                <div className="learro ">
                  <button onClick={handlePrevtClick} className="cursor-pointer">
                    <img src="images/Arrow.png" alt="arrow" />
                  </button>
                </div>

                <p className="block mx-2 ">
                  <span>{currentIndex} /</span> <span> 4</span>
                </p>

                <div className="learro">
                  <button
                    onClick={handleNextClick}
                    className="cursor-pointor transition-all duration-1000 ]"
                  >
                    <img
                      src="images/Arrow2.png"
                      className=" duration-1000 transition-all"
                      alt="arrow"
                    />
                  </button>
                </div>

              </div>
            </div>

          </div>


          <div className="right_fourth  w-[45%] ">
            <h4 id="next" className="text-left font-[Inter] desktop text-[18px]  leading-[20px] font-[500] ">
              Work Process
            </h4>
            <h2 className="mt-[26px] text-[70px] desktop  leading-[70px]  font-[400]">
              Wondering, how we work?
            </h2>

            <ul className="list-none listing_link mt-[70px] cursor-pointer  md:-ms-5 lg:ms-0">

              <li onMouseEnter={firstLink} className="flex items-center ">
                <img
                  src="images/vlogoicon1.png"
                  alt="v logo icon"
                  className="w-[24px] mr-[10px] font-[400] vlogoicon h-[11.95px]"
                />{' '}
                <span
                  style={{ marginLeft: fLink }}

                  className="text-[16px] leading-[172%] py-4 font-[Inter] bg-[#f7f7f7] "
                >
                  Send brief
                </span>
              </li>

              <hr className="h-[0px] bg-[#131519] border-[#131519]" />
              <li onMouseEnter={secondLink} className="flex items-center">
                <img
                  // style={{ marginLeft : sLink }}
                  src="images/vlogoicon1.png"
                  alt="v logo icon"
                  className="w-[24px]  mr-[10px] font-[400] vlogoicon h-[11.95px]"
                />{' '}
                <span
                  style={{ marginLeft: sLink }}

                  className="text-[16px] leading-[172%] py-4 font-[Inter] bg-[#f7f7f7] "
                >
                  Stay in touch
                </span>
              </li>
              <hr className="h-[0px] bg-[#131519] border-[#131519]" />
              <li onMouseEnter={thirdLink} className="flex items-center">
                <img
                  // style={{ marginLeft : tLink }}
                  src="images/vlogoicon1.png"
                  alt="v logo icon"
                  className="w-[24px]   mr-[10px] font-[400] vlogoicon h-[11.95px]"
                />{' '}
                <span
                  style={{ marginLeft: tLink }}
                  className="text-[16px] leading-[172%] py-4 font-[Inter] bg-[#f7f7f7] "
                >
                  Approve
                </span>
              </li>
              <hr className="h-[0px] bg-[#131519] border-[#131519]" />
              <li onMouseEnter={fourthLink} className="flex     items-center">

                <img
                  // style={{ marginLeft : gLink }}
                  // src="images/vlogoicon1.png"
                  src="images/vlogoicon1.png"

                  alt="v logo icon"
                  className="w-[24px]   mr-[10px] font-[400] vlogoicon h-[11.95px]"
                />
                <span
                  style={{ marginLeft: gLink }}

                  className="text-[16px] leading-[172%] font-[Inter] py-4 bg-[#f7f7f7] "
                >
                  Get result
                </span>
              </li>
              <hr className="h-[0px] bg-[#131519] border-[#131519]" />
            </ul>

          </div>

        </div>

        <hr className="py-[20px] mt-[100px] invisible" />
      </div>

      <div className="mt-[0px] desktop w-full ">
        <div className="container mx-auto  w-10/12">
          <div className="w-[100%] bg-[#1315190D] mx-auto border-[3px] h-[346px] rounded-2xl  flex justify-center items-center flex-col  p-8">
            <p className="text-center font-[Inter] font-semibold text-[18px] text-[#131519]">Our Speciality</p>
            <h2 className="text-[60px] font-[400] leading-8 mt-8 text-[#131519]">
              We make complicated things{' '}
            </h2>
            <h2 className="text-[60px] font-[400] mt-0 text-[#131519]">easy to use.</h2>
          </div>
        </div>
      </div>

    </div>
  );
};

export default SevenSec;
