import React, { useState, useEffect } from 'react';
import SliderText from './SlideText';
import ThirdSec from './ThirdSec';
import SixSec from './SixSec';
import SevenSec from './Seven';
import EightSec from './EightSec';
import NineSec from './NineSec';

import Lets from '../components/Lets.js';
import FourthSecT from './FourthSecT';
import Folower from './ViderRoute/Folower';
import TestingVdo from './TestingVideoFile/TestingVdo';
import SixSecScrollNew from './ScrollExampleFile/SixSecScrollNew';
import { Link } from 'react-router-dom';
import NavBar from './NavBar/NavBar';
import useHomePageFourthSect from './HooksFile/useHomePageFourthSect';
import VideoFolower from './VideoFolower/VideoFolower';



const Home = () => {
  const [homePageFourthSectionData, Hforthrefetch, isLoading] = useHomePageFourthSect();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [visible, setVisible] = useState("none");
  const [vidContainer, setVidContainer] = useState('80%');
  const [folowerVisible, setFolowerVisible] = useState("block")

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      const vheight = window.innerHeight;
      const thershold = vheight * 0.6;
      if (currentPosition > thershold) {
        setVidContainer('100%');
      } else {
        setVidContainer('80%');
      }
    };
    window.addEventListener('scroll', handleScroll);
  }, []);

  const displayBlock = () => {
    setVisible("block")
    setFolowerVisible("none")

  }
  const displayNone = () => {
    setVisible("none")
    setFolowerVisible("block")
  }
  // https://briten-server.vercel.app/
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {

    const handleResize = () => {
      const y = window.innerWidth;
      setDisplayValue(y);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isPreLoading, setIsPreLoading] = useState(false);

  useEffect(() => {
    if (!homePageFourthSectionData) {
      setIsPreLoading(true);
      setTimeout(() => {
        setIsPreLoading(false);
      }, 3000);
    }
  }, [homePageFourthSectionData]);

  return (
    <div>
      {
        isLoading ? <div className='h-screen bg-[#2B2C2F] text-center text-4xl  w-full flex justify-center items-center'>
          <img className='h-[300px] w-[400px] rounded-full' src="https://i.ibb.co/4dGgQdj/loading.gif" alt="" /> </div>
          :
          <div className=' max-w-[1440px] mx-auto' >
            <div className='hidden lg:block'>
              <Folower folowerVisible={folowerVisible} ></Folower>
              <VideoFolower showHide={visible} ></VideoFolower>
            </div>
            <div className='px-0 md:px-10 lg:px-14 max-w-[1440px] mx-auto'>
              <NavBar></NavBar>
            </div>

            <div className="heroImg flex justify-center items-center ">
              <div className=" flex justify-center items-center flex-col mt-5 md:mt-0">
                <h3 className=" text-[32px] md:text-[40px] lg:text-[48px] text-center homeTitle1 font-[400] mb-0">
                  Design or Development
                </h3>
                <h1 className=" homeTitle text-center text-[40px] md:text-[72px] lg:text-[96px] leading-[96px] font-[400] mt-0">
                  We Always Go Unique.
                </h1>
              </div>
            </div>
            <div
              onMouseEnter={displayBlock}
              onMouseLeave={displayNone}
              className="vidContainer relative mx-auto "
              style={{
                width: window.innerWidth <= 768 ? 'auto' : vidContainer,
              }}
            >
              <TestingVdo borderRadius={vidContainer} ></TestingVdo>
            </div>

            <SliderText />
            <ThirdSec />
            <FourthSecT homePageFourthSectionData={homePageFourthSectionData} Hforthrefetch={Hforthrefetch} ></FourthSecT>
            <span id='previous'></span>
            <div className='hidden lg:block'>
              <SixSecScrollNew></SixSecScrollNew>
            </div>
            <SixSec />
            <SevenSec />
            <EightSec />
            <Lets></Lets>
            <NineSec />
            <Link to="/contact">
              <div className={`flex flex-row justify-content-end align-items-center cursor-pointer contact-us-btn`}>
                <img
                  src="images/contactus.png"
                  alt="CONTACT us"
                  className="contact_png"
                />
                <img src='images/whiteChatsCircle.png' className='contact-us-chat-icon ' />
              </div>
            </Link>
          </div>
      }

    </div>
  );
};

export default Home;































// import React, { useState, useEffect } from 'react';
// import VideoPlayer from './video';
// import SliderText from './SlideText';
// import ThirdSec from './ThirdSec';
// import SixSec from './SixSec';
// import SevenSec from './Seven';
// import EightSec from './EightSec';
// import NineSec from './NineSec';

// import Lets from '../components/Lets.js';
// import FourthSecT from './FourthSecT';
// import Folower from './ViderRoute/Folower';
// import TestingVdo from './TestingVideoFile/TestingVdo';
// import SixSecScrollNew from './ScrollExampleFile/SixSecScrollNew';
// import { Link } from 'react-router-dom';
// import NavBar from './NavBar/NavBar';
// import useHomePageFourthSect from './HooksFile/useHomePageFourthSect';



// const Home = () => {
//   const [homePageFourthSectionData, Hforthrefetch, isLoading] = useHomePageFourthSect();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   const [visible, setVisible] = useState("none");
//   const [vidContainer, setVidContainer] = useState('80%');
//   const [folowerVisible, setFolowerVisible] = useState("block")

//   useEffect(() => {
//     const handleScroll = () => {
//       const currentPosition = window.scrollY;
//       const vheight = window.innerHeight;
//       const thershold = vheight * 0.6;
//       if (currentPosition > thershold) {
//         setVidContainer('100%');
//       } else {
//         setVidContainer('80%');
//       }
//     };
//     window.addEventListener('scroll', handleScroll);
//   }, []);

//   const displayBlock = () => {
//     setVisible("block")
//     setFolowerVisible("none")

//   }
//   const displayNone = () => {
//     setVisible("none")
//     setFolowerVisible("block")
//   }
//   // https://briten-server.vercel.app/
//   const [displayValue, setDisplayValue] = useState(0);

//   useEffect(() => {

//     const handleResize = () => {
//       const y = window.innerWidth;
//       setDisplayValue(y);
//     };
//     window.addEventListener('resize', handleResize);
//     handleResize();
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   const [isPreLoading, setIsPreLoading] = useState(false);

//   useEffect(() => {
//     if (!homePageFourthSectionData) {
//       setIsPreLoading(true);
//       setTimeout(() => {
//         setIsPreLoading(false);
//       }, 3000);
//     }
//   }, [homePageFourthSectionData]);

//   return (
//     <div>
//       {
//         isLoading ? <div className='h-screen bg-[#2B2C2F] text-center text-4xl  w-full flex justify-center items-center'>
//           <img className='h-[300px] w-[400px] rounded-full' src="https://i.ibb.co/4dGgQdj/loading.gif" alt="" /> </div>
//           :
//           <div className=' max-w-[1440px] mx-auto' >
//             <div className='hidden lg:block'>
//               <Folower folowerVisible={folowerVisible} ></Folower>
//             </div>
//             <div className='px-0 md:px-10 lg:px-14 max-w-[1440px] mx-auto'>
//               <NavBar></NavBar>
//             </div>
//             <div className="heroImg">
//               <div className="h-full heroimg1 flex justify-center items-center flex-col mt-[-20px]">
//                 <h3 className=" text-[32px] md:text-[40px] lg:text-[48px] text-center homeTitle1 font-[400] mb-0">
//                   Design or Development
//                 </h3>
//                 <h1 className=" homeTitle text-center text-[40px] md:text-[72px] lg:text-[96px] leading-[96px] font-[400] mt-0">
//                   We Always Go Unique.
//                 </h1>
//               </div>
//             </div>
//             <div
//               onMouseEnter={displayBlock}
//               onMouseLeave={displayNone}
//               className="vidContainer relative mx-auto -mt-28 md:mt-0 lg:mb-10 lg:-mt-16"
//               style={{
//                 width: window.innerWidth <= 768 ? 'auto' : vidContainer,
//                 height: window.innerWidth <= 768 ? '240px' : null,
//               }}
//             >
//               <TestingVdo borderRadius={vidContainer} visible={visible} ></TestingVdo>
//             </div>
//             <div className="vidContainer1 relative mx-auto mb-10  ">
//               <div className='flex justify-content-center items-center'>
//                 <img
//                   src="images/contactus.png"
//                   alt="CONTACT us"
//                   className="contact_png"
//                 />
//                 <img src='images/ChatsCircle.png' />
//               </div>
//               <VideoPlayer style={{ cursor: 'url(images/soundplay.png), auto' }} />
//             </div>
//             <SliderText />
//             <ThirdSec />
//             <FourthSecT homePageFourthSectionData={homePageFourthSectionData} Hforthrefetch={Hforthrefetch} ></FourthSecT>
//             <span id='previous'></span>
//             <div className='hidden lg:block'>
//               <SixSecScrollNew></SixSecScrollNew>
//             </div>
//             <SixSec />
//             <SevenSec />
//             <EightSec />
//             <Lets></Lets>
//             <NineSec />
//             <Link to="/contact">
//               <div className={`flex flex-row justify-content-end align-items-center cursor-pointer contact-us-btn`}>
//                 <img
//                   src="images/contactus.png"
//                   alt="CONTACT us"
//                   className="contact_png"
//                 />
//                 <img src='images/whiteChatsCircle.png' className='contact-us-chat-icon ' />
//               </div>
//             </Link>
//           </div>
//       }

//     </div>
//   );
// };

// export default Home;



