import React, { useContext, useEffect, useState } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Arrow from "../components/assets/images/Arrow.png";
import Lets from "../components/Lets";
import "../style/CaseStudy.css"
import { AuthContext } from "../components/HooksFile/AuthContextProvider";
import Folower from "../components/ViderRoute/Folower";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Parallax } from "react-parallax";
import NavBar from "../components/NavBar/NavBar";


const CaseStudy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { user, adminEmail } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [successText, setSuccessText] = useState("")

  setTimeout(() => {
    setSuccessText("")
  }, 3000);

  const location = useLocation();
  const id = location.pathname.split("/")[2]

  const { data: SixSecScrollData, isLoading, refetch } = useQuery({
    queryKey: ['sixSecAllProjectSingleData'],
    enabled: !!id,
    queryFn: async () => {
      const res = await fetch(`https://briten-server.vercel.app/sixSecAllProjectSingleData/${id}`);
      const data = await res.json();
      setData(data);
    },
  });


  const fourthSectionSubFun = (e) => {
    e.preventDefault();
    const category = e.target.category.value || data?.category;
    // const projectTitle = e.target.projectTitle.value || data?.projectTitle;
    const displayTitle = e.target.displayTitle.value || data?.displayTitle;
    const displayDiscreption = e.target.displayDiscreption.value || data?.displayDiscreption;
    const displayImage = e.target.displayImage.value || data?.displayImage;
    const SecondSecTitle = e.target.SecondSecTitle.value || data?.SecondSecTitle;
    const secondSecFirstLi = e.target.secondSecFirstLi.value || data?.secondSecFirstLi;
    const secondSecSecondLi = e.target.secondSecSecondLi.value || data?.secondSecSecondLi;
    const secondSecImage = e.target.secondSecImage.value || data?.secondSecImage;
    const thirdSecTitle = e.target.thirdSecTitle.value || data?.thirdSecTitle;
    const thirdSecFirstLi = e.target.thirdSecFirstLi.value || data?.thirdSecFirstLi;
    const thirdSecSecondLi = e.target.thirdSecSecondLi.value || data?.thirdSecSecondLi;
    const thirdSecImage = e.target.thirdSecImage.value || data?.thirdSecImage;
    const forthSecFirstImage = e.target.forthSecFirstImage.value || data?.forthSecFirstImage;
    const forthSecSecondImage = e.target.forthSecSecondImage.value || data?.forthSecSecondImage;
    const forthSecThirdImage = e.target.forthSecThirdImage.value || data?.forthSecThirdImage;
    const forthSecVideoUrl = e.target.forthSecVideoUrl.value || data?.forthSecVideoUrl;
    const forthSecForthImage = e.target.forthSecForthImage.value || data?.forthSecForthImage;
    const forthSecFifthImage = e.target.forthSecFifthImage.value || data?.forthSecFifthImage;
    const FifthSecTitle = e.target.FifthSecTitle.value || data?.FifthSecTitle;
    const fifthSecDescription = e.target.fifthSecDescription.value || data?.fifthSecDescription;
    const fifthSecImage = e.target.fifthSecImage.value || data?.fifthSecImage;
    const sixSecVideoUrl = e.target.sixSecVideoUrl.value || data?.sixSecVideoUrl;
    const sixSecFirstImage = e.target.sixSecFirstImage.value || data?.sixSecFirstImage;
    const sixSecSecondImage = e.target.sixSecSecondImage.value || data?.sixSecSecondImage;
    const sixSecThirdImage = e.target.sixSecThirdImage.value || data?.sixSecThirdImage;
    const sixSecForthImage = e.target.sixSecForthImage.value || data?.sixSecForthImage;
    const sixSecFifthImage = e.target.sixSecFifthImage.value || data?.sixSecFifthImage;
    const sevenSectionTitle = e.target.sevenSectionTitle.value || data?.sevenSectionTitle;
    const sevenSectionImage = e.target.sevenSectionImage.value || data?.sevenSectionImage;
    const time = e.target.time.value || data?.time;
    const siteLink = e.target.siteLink.value || data?.siteLink;
    const getData = {
      category: category,
      // projectTitle: projectTitle,
      displayTitle: displayTitle,
      displayDiscreption: displayDiscreption,
      displayImage: displayImage,
      SecondSecTitle: SecondSecTitle,
      secondSecFirstLi: secondSecFirstLi,
      secondSecSecondLi: secondSecSecondLi,
      secondSecImage: secondSecImage,
      thirdSecTitle: thirdSecTitle,
      thirdSecFirstLi: thirdSecFirstLi,
      thirdSecSecondLi: thirdSecSecondLi,
      thirdSecImage: thirdSecImage,
      forthSecFirstImage: forthSecFirstImage,
      forthSecSecondImage: forthSecSecondImage,
      forthSecThirdImage: forthSecThirdImage,
      forthSecVideoUrl: forthSecVideoUrl,
      forthSecForthImage: forthSecForthImage,
      forthSecFifthImage: forthSecFifthImage,
      FifthSecTitle: FifthSecTitle,
      fifthSecDescription: fifthSecDescription,
      fifthSecImage: fifthSecImage,
      sixSecVideoUrl: sixSecVideoUrl,
      sixSecFirstImage: sixSecFirstImage,
      sixSecSecondImage: sixSecSecondImage,
      sixSecThirdImage: sixSecThirdImage,
      sixSecForthImage: sixSecForthImage,
      sixSecFifthImage: sixSecFifthImage,
      sevenSectionTitle: sevenSectionTitle,
      sevenSectionImage: sevenSectionImage,
      time: time,
      siteLink: siteLink,
    }

    fetch(`https://briten-server.vercel.app/sixSecAllProjectData/${data?._id}`, {
      method: 'PATCH',
      body: JSON.stringify(getData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => {
        console.log(response)
        if (response.ok) {
          setSuccessText("Updated Your Data")
          e.target.reset();
          refetch();
        }
      })
      .then((json) => console.log(json));

  }


  return (
    <div>
      {
        isLoading ? <div className='h-screen bg-[#2B2C2F] text-center text-4xl  w-full flex justify-center items-center'>
          <img className='h-[300px] w-[400px] rounded-full' src="https://i.ibb.co/4dGgQdj/loading.gif" alt="" /> </div>
          :
          <div className="  px-0 md:px-10 lg:px-14 caseStudyMainDiv max-w-[1440px] mx-auto">
          <div className="hidden lg:block">
            <Folower></Folower>
          </div>
          <NavBar></NavBar>
          <section className="section mt-28 px-4">
            <section>
              <div className="lg:flex">
                <div className="lg:w-1/2 pr-4">
                  <div className="font-[Inter] text-gray-500">Case Study</div>
                  <div className="small-device-heading sm:md-device-heading lg:lg-device-heading  text-left mt-[8px] md:mt-0">
                    {data?.displayTitle}
                  </div>
                  <div
                    className="
                        flex-wrap
                        justify-start
                        flex gap-2 text-xl font-sans"
                  >
                    <button className="my-btn font-[Inter]">UI/UX design</button>
                    <button className="my-btn font-[Inter]">Website from scratch</button>
                    <button className="my-btn font-[Inter]">Branding</button>
                    <button className="my-btn font-[Inter]">Website redesign</button>
                    <button className="my-btn font-[Inter]">App from scratch</button>
                    <button className="my-btn font-[Inter]">App redesign</button>
                    <button className="my-btn font-[Inter]">Not sure yet</button>
                    <button className="my-btn font-[Inter]">Let’s talk first</button>
                    <button className="my-btn font-[Inter]">Maintenance</button>
                  </div>
    
    
                  {
                    user?.email === adminEmail &&
                    <button onClick={() => document.getElementById('updateProject').showModal()} className=" update-button" >Update</button>
                  }
                  <dialog id="updateProject" className="modal">    
                    <div className="modal-box w-11/12  max-w-5xl bg-sky-300">    
                      <form onSubmit={fourthSectionSubFun} className=' font-mono text-sm mt-14' >
                        <input className='w-full rounded-md mt-2 p-2' name='category' type="text" placeholder={`${data?.category} , category `} />
                        {/* <input className='w-full rounded-md mt-2 p-2' name='projectTitle' type="text" placeholder={data?.projectTitle} /> */}
                        <input className='w-full rounded-md mt-2 p-2' name='displayTitle' type="text" placeholder={data?.displayTitle} />
                        <textarea className='w-full rounded-md mt-2 p-2' name='displayDiscreption' type="text" placeholder={data?.displayDiscreption} />
                        <input className='w-full rounded-md mt-2 p-2' name='displayImage' type="text" placeholder='Banner Photo Url' />
                        <input className='w-full rounded-md mt-2 p-2' name='SecondSecTitle' type="text" placeholder='Second Section Title' />
                        <textarea className='w-full rounded-md mt-2 p-2' name='secondSecFirstLi' type="text" placeholder='Second Section First Paragraph ' />
                        <textarea className='w-full rounded-md mt-2 p-2' name='secondSecSecondLi' type="text" placeholder='Second Section Second Paragraph ' />
                        <input className='w-full rounded-md mt-2 p-2' name='secondSecImage' type="text" placeholder='Second Section photo Url' />
                        <input className='w-full rounded-md mt-2 p-2' name='thirdSecTitle' type="text" placeholder='Third Section Title' />
                        <textarea className='w-full rounded-md mt-2 p-2' name='thirdSecFirstLi' type="text" placeholder='Third Section First Paragraph' />
                        <textarea className='w-full rounded-md mt-2 p-2' name='thirdSecSecondLi' type="text" placeholder='Third Section Second Paragraph' />
                        <input className='w-full rounded-md mt-2 p-2' name='thirdSecImage' type="text" placeholder='Third Section Photo Url' />
                        <input className='w-full rounded-md mt-2 p-2' name='forthSecFirstImage' type="text" placeholder='Forth Section First photo Url' />
                        <input className='w-full rounded-md mt-2 p-2' name='forthSecSecondImage' type="text" placeholder='Forth Section Second photo Url' />
                        <input className='w-full rounded-md mt-2 p-2' name='forthSecThirdImage' type="text" placeholder='Forth Secction Third photo Url' />
                        <input className='w-full rounded-md mt-2 p-2' name='forthSecVideoUrl' type="text" placeholder='Forth Section Video Url' />
                        <input className='w-full rounded-md mt-2 p-2' name='forthSecForthImage' type="text" placeholder='Forth Section Forth photo Url' />
                        <input className='w-full rounded-md mt-2 p-2' name='forthSecFifthImage' type="text" placeholder='Forth Section Fifth photo Url' />
                        <input className='w-full rounded-md mt-2 p-2' name='FifthSecTitle' type="text" placeholder='Fifth Section Title' />
                        <textarea className='w-full rounded-md mt-2 p-2' name='fifthSecDescription' type="text" placeholder='Fifth Section Description' />
                        <input className='w-full rounded-md mt-2 p-2' name='fifthSecImage' type="text" placeholder='Fifth Section photo Url' />
                        <input className='w-full rounded-md mt-2 p-2' name='sixSecVideoUrl' type="text" placeholder='six Section Video Url' />
                        <input className='w-full rounded-md mt-2 p-2' name='sixSecFirstImage' type="text" placeholder='six Section First photo Url' />
                        <input className='w-full rounded-md mt-2 p-2' name='sixSecSecondImage' type="text" placeholder='six Section Second photo Url' />
                        <input className='w-full rounded-md mt-2 p-2' name='sixSecThirdImage' type="text" placeholder='six Section Third photo Url' />
                        <input className='w-full rounded-md mt-3 p-2' name='sixSecForthImage' type="text" placeholder='six Section Forth photo Url' />
                        <input className='w-full rounded-md mt-3 p-2' name='sixSecFifthImage' type="text" placeholder='six Section Fifth photo Url' />
                        <input className='w-full rounded-md mt-3 p-2' name='sevenSectionTitle' type="text" placeholder='Seven Section Title' />
                        <input className='w-full rounded-md mt-3 p-2' name='sevenSectionImage' type="text" placeholder='seven Section Photo Url ' />
                        <input className='w-full rounded-md mt-3 p-2' name='time' type="text" placeholder='set Time' />
                        <input className='w-full rounded-md mt-3 p-2' name='siteLink' type="text" placeholder=' Project Live Link ' />
    
    
    
                        <input className='w-full mt-2 h-12 bg-gray-300 text-xl font-semibold rounded-md hover:text-red-500 hover:text-2xl duration-500 cursor-pointer' type="submit" value="submit" />
                      </form>
                      {
                        successText && <h2 className="text-center text-xl "> {successText} </h2>
                      }
    
                      <div className="modal-action absolute top-2 right-2 ">
                        <form method="dialog">
                          <button className="btn">X</button>
                        </form>
                      </div>
                    </div>
    
                  </dialog>
                </div>
    
                <div className="mt-10 lg:w-1/2 md:mt-0">
                  <div className="font-[Inter] text-gray-500"> About the project </div>
                  <div className="small-device-paragraph sm:md-device-paragraph text-left mt-[8px] md:mt-0">
    
                    {data?.displayDiscreption}
    
                    <div className="mt-10 flex justify-between items-center">
                      <a href={data?.siteLink} target="_blank" className="my-btnn">
                        <span className=" ">Website</span>
    
                        <img
                          className="rotate-180 scale-x-150 my-auto ml-2"
                          src={Arrow}
                          alt=""
                        />
                      </a>
    
                    </div>
                  </div>
                </div>
    
              </div>
            </section>
    
            <section className="mt-[80px] md:mt-[120px] caseStudy-paralax-style">
    
              <div className='parallax-content' >
                <Parallax
                  className="displayImage"
                  bgImage={data?.displayImage}
                  strength={80}
                >
                  <div className='child-content' >
                    <div className="content">
    
                    </div>
                  </div>
                </Parallax>
              </div>
    
    
            </section>
    
            <div className="mt-[40px] md:mt-[120px] grid grid-cols-1 md:grid-cols-6 gap-5 lg:gap-10 ">
              <h4 className="text-[40px] md:text-[48px] md:col-span-2 lg:text-[72px] font-[NewYork]  mt-10 text-left">
                {data?.SecondSecTitle}
              </h4>
    
              <ul className=" list-disc w-full font-[Inter] md:col-span-4 md:mt-10 text-left text-[16px] lg:text-[28px] pl-4 md:pl-0">
                <li>
                  {data?.secondSecFirstLi}
                </li>
                <li>
                  {data?.secondSecSecondLi}
                </li>
              </ul>
            </div>
            {/* second section  */}
            <section className="mt-[80px] md:mt-[120px] caseStudy-paralax-style">
              <div className='parallax-content' >
                <Parallax
                  className="displayImage"
                  bgImage={data?.secondSecImage}
                  strength={80}
                >
                  <div className='child-content' >
                    <div className="content second-section-image">
                    </div>
                  </div>
                </Parallax>
              </div>
            </section>
    
            {/* third Section */}
            <section className="mt-[40px] md:mt-[120px] flex items-center md:flex md:flex-row-reverse caseStudy-paralax-third-style">
    
              {/* <div className="md:w-[60%] md:mt-16"> */}
              <div className="md:w-[60%]">
                <div className=" flex justify-between items-center">
                  <h1 className="small-device-heading sm:md-device-heading lg:lg-device-heading text-left">
                    {data?.thirdSecTitle}
                  </h1>
    
                </div>
                <div className="">
                  <ul className="small-device-paragraph sm:md-device-paragraph text-left">
                    <li>
                      {data?.thirdSecFirstLi}
                    </li>
                    <li>
                      {data?.thirdSecSecondLi}
                    </li>
                  </ul>
                </div>
              </div>
    
              <div className="md:w-[40%] md:pr-8">
    
                <div className='parallax-content-third-image' >
                  <Parallax
                    bgImage={data?.thirdSecImage}
                    strength={70}
                    style={{
                    }}
                  >
                    <div className='child-content' >
                      <div className="content">
    
                      </div>
                    </div>
                  </Parallax>
                </div>
              </div>
            </section>
    
            {/* firse gallary */}
            <section className="mt-[40px] md:mt-[120px] md:gap-6 caseStudy-paralax-forth-style">
    
              <div className="media-section flex flex-col md:flex-row justify-between lg:px-10">
    
                <div className="left-side flex flex-col gap-10 w-full md:w-6/12 md:px-3 lg:px-10">
    
                  <div className=" ">
    
                    {/* <img className="w-full rounded-3xl" src={data?.forthSecFirstImage} alt="" /> */}
                    <div className='parallax-content' >
                      <Parallax
                        className="forthSecFirstImage"
                        bgImage={data?.forthSecFirstImage}
                        strength={80}
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundAttachment: 'fixed',
                        }}
                      >
                        <div className='child-content' >
                          <div className="content forthSecFirstImage-div">
    
                          </div>
                        </div>
                      </Parallax>
                    </div>
    
                  </div>
    
                  <div className=" ">
                    <div className='parallax-content' >
                      <Parallax
                        className="forthSecSecondImage"
                        bgImage={data?.forthSecSecondImage}
                        strength={80}
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundAttachment: 'fixed',
                        }}
                      >
                        <div className='child-content' >
                          <div className="content second-section-image">
    
                          </div>
                        </div>
                      </Parallax>
                    </div>
                  </div>
    
                  <div className=" ">
                    <div className='parallax-content' >
                      <Parallax
                        className="forthSecThirdImage"
                        bgImage={data?.forthSecThirdImage}
                        strength={80}
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundAttachment: 'fixed',
                        }}
                      >
                        <div className='child-content' >
                          <div className="content second-section-image">
    
                          </div>
                        </div>
                      </Parallax>
                    </div>
    
    
                  </div>
    
                </div>
    
    
                <div className="right-side flex flex-col gap-10 w-full md:w-6/12 md:px-3 lg:px-10 md:mt-[100px]">
                  <div className="mt-10 md:mt-0 ">
                    <div className='parallax-content' >
                      <Parallax
                        className="forthSecForthImage"
                        bgImage={data?.forthSecForthImage}
                        strength={80}
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundAttachment: 'fixed',
                        }}
                      >
                        <div className='child-content' >
                          <div className="content second-section-image">
    
                          </div>
                        </div>
                      </Parallax>
                    </div>
                  </div>
    
                  <div className=" ">
    
                    <iframe className="h-[500px] w-full rounded-3xl" src={`${data?.forthSecVideoUrl}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write;
                         encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen autoplay  ></iframe>
    
                  </div>
    
                  <div className=" ">
                    {/* <img className="w-full rounded-3xl" src={data?.forthSecFifthImage} alt="" /> */}
                    <div className='parallax-content' >
                      <Parallax
                        className="forthSecFifthImage"
                        bgImage={data?.forthSecFifthImage}
                        strength={80}
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundAttachment: 'fixed',
                        }}
                      >
                        <div className='child-content' >
                          <div className="content second-section-image">
    
                          </div>
                        </div>
                      </Parallax>
                    </div>
                  </div>
    
                </div>
    
              </div>
    
            </section>
    
            {/* fifth Section */}
            <section className="mt-[80px] md:mt-[120px] caseStudy-paralax-style">
              {/* <img className="w-full rounded-3xl" src={data?.fifthSecImage} alt="" /> */}
    
              <div className='parallax-content ' >
                <Parallax
                  className="displayImage"
                  bgImage={data?.fifthSecImage}
                  strength={80}
                >
                  <div className='child-content' >
                    <div className="content">
    
                    </div>
                  </div>
                </Parallax>
              </div>
    
    
            </section>
    
            <section className="mt-[80px] md:mt-[120px] md:flex">
              <div className="md:w-[50%] lg:w-[40%] md:pr-10 ">
                <h1 className="my-title text-left mt-10 font-thin md:text-[40px] lg:text-[48px] font-[NewYork] ">
                  {data?.FifthSecTitle}
                </h1>
              </div>
              <div className="md:w-[50%] lg:w-[60%] mt-10">
                <p className="font-sans">
                  {data?.fifthSecDescription}
                </p>
              </div>
    
            </section>
    
            {/* second gallary */}
            <section className="mt-[80px] md:mt-[120px]  md:gap-6 caseStudy-paralax-six-style">
              <div className="media-section flex flex-col md:flex-row justify-between lg:px-10">
    
                <div className="left-side flex flex-col gap-10  w-full md:w-6/12 md:px-3 lg:px-10">
    
                  <div className=" ">
                    <iframe className="h-[536px] w-full rounded-3xl" src={data?.sixSecVideoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
    
                  <div className=" ">
                    <div className='parallax-content-six' >
                      <Parallax
                        className="sixSecFirstImage"
                        bgImage={data?.sixSecFirstImage}
                        strength={80}
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundAttachment: 'fixed',
                        }}
                      >
                        <div className='child-content' >
                          <div className="content six-image">
    
                          </div>
                        </div>
                      </Parallax>
                    </div>
    
                  </div>
    
                  <div className="">
                    <div className='parallax-content-six' >
                      <Parallax
                        className="sixSecSecondImage"
                        bgImage={data?.sixSecSecondImage}
                        strength={80}
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundAttachment: 'fixed',
                        }}
                      >
                        <div className='child-content' >
                          <div className="content six-image">
    
                          </div>
                        </div>
                      </Parallax>
                    </div>
                  </div>
    
                </div>
    
                <div className="right-side flex flex-col gap-10 w-full md:w-6/12 md:px-3 lg:px-10 md:mt-[100px]">
    
                  <div className=" mt-10 md:mt-0 ">
    
                    <div className='parallax-content-six' >
                      <Parallax
                        className="sixSecThirdImage"
                        bgImage={data?.sixSecThirdImage}
                        strength={80}
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundAttachment: 'fixed',
                        }}
                      >
                        <div className='child-content' >
                          <div className="content six-image">
    
                          </div>
                        </div>
                      </Parallax>
                    </div>
    
                  </div>
    
                  <div className=" ">
                    {/* <img className="w-full rounded-3xl" src={data?.sixSecForthImage} alt="" /> */}
                    <div className='parallax-content-six' >
                      <Parallax
                        className="sixSecForthImage"
                        bgImage={data?.sixSecForthImage}
                        strength={80}
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundAttachment: 'fixed',
                        }}
                      >
                        <div className='child-content' >
                          <div className="content six-image-forth-image">
    
                          </div>
                        </div>
                      </Parallax>
                    </div>
                  </div>
    
                  <div className=" ">
                    {/* <img className="w-full rounded-3xl" src={data?.sixSecFifthImage} alt="" /> */}
    
                    <div className='parallax-content-six' >
                      <Parallax
                        className="sixSecFifthImage"
                        bgImage={data?.sixSecFifthImage}
                        strength={80}
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundAttachment: 'fixed',
                        }}
                      >
                        <div className='child-content' >
                          <div className="content six-image-fifth-image">
    
                          </div>
                        </div>
                      </Parallax>
                    </div>    
                  </div>    
                </div>    
              </div>   
    
            </section>    
            <section className="mt-[80px] md:mt-[120px]">
              <h1 className="my-title text-left font-[NewYork] text-[40px] md:text-[48px] lg:text-[72px] w-10/12 md:w-9/12 lg:w-8/12 lg:ms-16 ">
                {data?.sevenSectionTitle}
              </h1>
            </section>
    
            <section className="mt-[80px] md:mt-[120px] caseStudy-paralax-style">
              <div className='parallax-content' >
                <Parallax
                  className="displayImage"
                  bgImage={data?.sevenSectionImage}
                  strength={80}
                >
                  <div className='child-content' >
                    <div className="content">
    
                    </div>
                  </div>
                </Parallax>
              </div>
            </section>
    
    
          </section>
    
    
    
    
          <div className="mt-[80px] md:mt-[120px]">
            <Lets  ></Lets>
          </div>
    
          <Footer></Footer>
          <Link to="/contact">
            <div className={`flex flex-row justify-content-end align-items-center cursor-pointer contact-us-btn `}>
              <img
                src="https://i.ibb.co/S0sQyrS/contactus.png"
                alt="CONTACT us"
                className="contact_png"
              />
              <img src='https://i.ibb.co/TvnHdRH/white-Chats-Circle.png' className='contact-us-chat-icon ' />
            </div>
          </Link>
    
    
    
        </div>
      }
    </div>

    

  );
};

export default CaseStudy;

