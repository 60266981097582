import { PiArrowBendDoubleUpRightBold } from "react-icons/pi";
import '../style/NineSec.css';
import { Link } from "react-router-dom";

const NineSec = () => {
  return (
    <div className="nineSec-main-div ">

      {/* <footer className="mt-40 w-[100%] md:w-[90%] h-[147px] mx-auto px-6 rounded-tl-[40px] rounded-tr-[40px] bg-black"> */}
      {/* <footer className="mt-40 w-[100%] md:w-[90%] mx-auto px-[48] py-[32px]  rounded-tl-[40px] rounded-tr-[40px] bg-black"> */}
      {/* <footer className="mt-40 w-[398px] md:w-[704px] lg:w-[1220px] mx-auto px-[40px] py-[12px]  rounded-tl-[40px] rounded-tr-[40px] bg-black"> */}
      <footer className="mt-40 w-[95%] lg:max-w-[1440px] mx-auto px-[40px] py-[12px]  rounded-tl-[40px] rounded-tr-[40px] bg-black">

          <div className="flex justify-between flex-col lg:flex-row pt-10 px-0 md:px-[75px] -mt-12 md:-mt-10 lg:mt-0 ">
              <div className="flex flex-col md:flex-row justify-between lg:justify-start md:pt-5 lg:pt-0 ">
                 
                  <div className=" w-52 mx-auto md:ms-0">
                    <div className=" border-r-[1px] border-rr flex justify-start items-center pr-5 mt-5 md:mt-0 h-[20px] border-gray-300">
                      <img
                        className="w-[15px] h-[28px] mr-3"
                        src="https://i.ibb.co/nR4Vt4P/footericon.png"
                        alt="footericon"
                      />
                      <span className="text-white font-[Inter]">Batin Studio</span>
                    </div>
                  </div>

                  <div className="  w-full flex justify-center  md:justify-evenly py-5 md:py-0 ">
                      <Link to="/" className="text-white mx-3 md:mx-2 text-[14px]  font-sans ">
                        Home
                      </Link>
                      <Link to="/about" className="text-white mx-3 md:mx-2 text-[14px]  font-sans ">
                        About
                      </Link>
                      <Link to="/portfolio" className="text-white mx-3 md:mx-2 text-[14px]  font-sans ">
                        Portfolio
                      </Link>
                      <Link to="/contact" className="text-white mx-3 md:mx-2 text-[14px]  font-sans ">
                        Contact
                      </Link>
                  </div>


              </div>


              <div className="social-service-div  flex justify-center lg:justify-end md:pt-10 lg:pt-0 ">


                <span className="hidden md:block">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/batin_studio/"
                    className="font-[Inter]"
                  >
                    <img
                      src="https://i.ibb.co/yNqdg5z/insta.png"
                      className="w-4 h-4 mr-2"
                      alt="insta"
                    />
                    Instagram <PiArrowBendDoubleUpRightBold className="ms-[8px]"></PiArrowBendDoubleUpRightBold>
                  </a>
                </span>

                <span className="hidden md:block" >
                <a
                    target="_blank"
                    href="https://github.com/Batin-Studio"
                    className="font-[Inter]"
                  >
                    <img
                      src="https://i.ibb.co/2ZJ3vLb/github.png"
                      className="w-4 h-4 mr-2 "
                      alt="insta"
                    />
                    Github <PiArrowBendDoubleUpRightBold className="ms-[8px]"></PiArrowBendDoubleUpRightBold>
                  </a>
                </span>

                <span className="hidden md:block">
                <a
                    target="_blank"
                    href="https://www.linkedin.com/company/batinstudio"
                    className="font-[Inter]"
                  >
                    <img
                      src="https://i.ibb.co/gyMmL98/linkedin.png"
                      className="w-4 h-4 mr-2"
                      alt="LinkedIn"
                    />
                    LinkedIn <PiArrowBendDoubleUpRightBold className="ms-[8px]"></PiArrowBendDoubleUpRightBold>
                  </a>

                </span>

                <span className="block md:hidden flex w-32">

                  <a 
                  target="_blank"
                  href="https://www.instagram.com/batin_studio/"
                  >
                    <img 
                    src="https://i.ibb.co/yNqdg5z/insta.png"
                    className="w-6 h-6 mr-2"
                    alt="insta"
                    />
                  </a>

                  <a 
                  target="_blank"
                  href="https://github.com/Batin-Studio"
                  >
                    <img 
                      src="https://i.ibb.co/2ZJ3vLb/github.png"
                      className="w-6 h-6 mr-2 "
                      alt="insta" />
                  </a>

                  <a 
                  target="_blank"
                  href="https://www.linkedin.com/company/batinstudio"
                  >
                    <img  
                    src="https://i.ibb.co/gyMmL98/linkedin.png"
                      className="w-6 h-6 mr-2"
                      alt="LinkedIn" />
                  </a>
                </span>
              </div>
          </div>

          <p className="text-gray-400 text-xs font-[Inter] w-full  text-center lg:pt-10 mt-6 lg:mt-0">
            © Batin Studio 2023 | All rights reserve <Link className="-ms-1" to={"/admin"}>d</Link>.
          </p> 
          
      </footer>

    </div>
  );
};

export default NineSec;

