import React from 'react';
import Marquee from 'react-fast-marquee';
function SliderText() {
  return (
    <div className=" mb-5 py-[70px] md:py-[90px] lg:py-[120px] ">
      <Marquee>
        <img className=' mx-[34px] h-[20px]' src="https://i.ibb.co/25PBrgf/vlogoicon1.png" alt="" />
        <h2 className=" mx-[34px] text-NewYork text-[54px] md:text-[72px] lg:text-[96px] text-[#131519] font-normal leading-96 tracking-tight text-left">Creative</h2>
        <img className=' mx-[34px] h-[20px]' src="https://i.ibb.co/25PBrgf/vlogoicon1.png" alt="" />
        <h2 className=" mx-[34px] text-NewYork text-[54px] md:text-[72px] lg:text-[96px] text-[#131519] font-normal leading-96 tracking-tight text-left">Design</h2>
        <img className=' mx-[34px] h-[20px]' src="https://i.ibb.co/25PBrgf/vlogoicon1.png" alt="" />
        <h2 className=" mx-[34px] text-NewYork text-[54px] md:text-[72px] lg:text-[96px] text-[#131519] font-normal leading-96 tracking-tight text-left">Creative</h2>
        <img className=' mx-[34px] h-[20px]' src="https://i.ibb.co/25PBrgf/vlogoicon1.png" alt="" />
        <h2 className=" mx-[34px] text-NewYork text-[54px] md:text-[72px] lg:text-[96px] text-[#131519] font-normal leading-96 tracking-tight text-left">Design</h2>
      </Marquee>
    </div>
  );
}
export default SliderText;


















// import React from 'react';
// import { images } from './images';
// function SliderText() {
//   return (
//     <div className=" flex textSlider items-center h-[50px] md:h-[250px] lg:h-[400px] justify-center -mt-10 md:-mt-5 lg:-mt-16 xl:mt-2 ">
//       {/* 1. */}
//       <div className="w-[200%] h-[80px] overflow-hidden relative">
//         {/* 2. */}
//         <div className="w-[200%] flex items-center h-20 justify-around absolute left-0 animate gap-20 animate">
//           {/* 3 */}
//           {images.map((i) => {
//             return (
//               <div className="flex justify-center items-center w-[200rem]">
//                 <h2 className="leading-[90px] text-[90px] font-[400]">
//                   {i.te1}
//                 </h2>

//                 <img src={i.img} className="mx-[50px]" alt={i.img} />

//                 <h2 className="leading-[90px]  ml-[30px] text-[90px] font-[400]">
//                   {i.te2}
//                 </h2>

//                 <img src={i.img} className="mx-[50px] w-[]" alt={i.img} />
//               </div>
//             );
//           })}
//           {images.map((i) => {
//             return (
//               <div className="flex justify-center items-center w-[40rem]">
//                 <h2 className="leading-[90px] text-[90px] font-[400]">
//                   {i.te1}
//                 </h2>

//                 <img src={i.img} className="mx-[50px] w-[]" alt={i.img} />

//                 <h2 className="leading-[90px] ml-[30px]  text-[90px] font-[400]">
//                   {i.te2}
//                 </h2>

//                 <img src={i.img} className="mx-[50px] w-[]" alt={i.img} />
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default SliderText;
