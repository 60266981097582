import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Reload from "../components/assets/images/reload.svg";
import Lets from "../components/Lets";
import CustomCursor from "../components/CustomCursor";
import { Link } from "react-router-dom";
import usePortfolio from "../components/HooksFile/usePortfolio";
import Folower from "../components/ViderRoute/Folower";
import { AuthContext } from "../components/HooksFile/AuthContextProvider";
import useSixSecAllProjectData from "../components/HooksFile/useSixSecAllProjectData";
import NavBar from "../components/NavBar/NavBar";
import '../style/Portfolio.css';
import { PiArrowBendDoubleUpRightBold } from "react-icons/pi";

const Portfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [customCursor, setCustomCursor] = useState('none');
  const [visible, setVisible] = useState(10);
  const [allDesign, setAllDesign] = useState([]);
  const [PortfolioData, isLoading, refetch] = usePortfolio(allDesign);
  const [ulUxDesign, setulUxDesign] = useState("")
  const [Website, setWebsite] = useState("")
  const [apps, setApps] = useState("")
  const [branding, setBranding] = useState("");
  const [sixSecAllProjectData] = useSixSecAllProjectData();
  const [cursorImg, setCursorImg] = useState('');


  const [successText, setSuccessText] = useState("")

  setTimeout(() => {
    setSuccessText("")
  }, 3000);
  const { user, adminEmail } = useContext(AuthContext);


  let updatedAllDesign = [];
  const clearAllFun = () => {
    updatedAllDesign = []
    setAllDesign(updatedAllDesign)
    setulUxDesign("")
    setWebsite("")
    setApps("")
    setBranding("")
    setVisible(10)
  }
  useEffect(() => {
    if (ulUxDesign) {
      updatedAllDesign.push(ulUxDesign);
    }
    if (Website) {
      updatedAllDesign.push(Website);
    }
    if (apps) {
      updatedAllDesign.push(apps);
    }
    if (branding) {
      updatedAllDesign.push(branding);
    }
    setAllDesign(updatedAllDesign);
  }, [ulUxDesign, Website, apps, branding]);


  const showMore = () => {
    setVisible((preValue) => preValue + 10);
  };

  const fourthSectionSubFun = (e) => {
    e.preventDefault();

    // console.log(data.FifthSecTitle)
    const projectTitle = e.target.projectTitle.value;
    const category = e.target.category.value;
    const displayTitle = e.target.displayTitle.value;
    const displayDiscreption = e.target.displayDiscreption.value;
    const displayImage = e.target.displayImage.value;
    const SecondSecTitle = e.target.SecondSecTitle.value;
    const secondSecFirstLi = e.target.secondSecFirstLi.value;
    const secondSecSecondLi = e.target.secondSecSecondLi.value;
    const secondSecImage = e.target.secondSecImage.value;
    const thirdSecTitle = e.target.thirdSecTitle.value;
    const thirdSecFirstLi = e.target.thirdSecFirstLi.value;
    const thirdSecSecondLi = e.target.thirdSecSecondLi.value;
    const thirdSecImage = e.target.thirdSecImage.value;
    const forthSecFirstImage = e.target.forthSecFirstImage.value;
    const forthSecSecondImage = e.target.forthSecSecondImage.value;
    const forthSecThirdImage = e.target.forthSecThirdImage.value;
    const forthSecVideoUrl = e.target.forthSecVideoUrl.value;
    const forthSecForthImage = e.target.forthSecForthImage.value;
    const forthSecFifthImage = e.target.forthSecFifthImage.value;
    const FifthSecTitle = e.target.FifthSecTitle.value;
    const fifthSecDescription = e.target.fifthSecDescription.value;
    const fifthSecImage = e.target.fifthSecImage.value;
    const sixSecVideoUrl = e.target.sixSecVideoUrl.value;
    const sixSecFirstImage = e.target.sixSecFirstImage.value;
    const sixSecSecondImage = e.target.sixSecSecondImage.value;
    const sixSecThirdImage = e.target.sixSecThirdImage.value;
    const sixSecForthImage = e.target.sixSecForthImage.value;
    const sixSecFifthImage = e.target.sixSecFifthImage.value;
    const sevenSectionTitle = e.target.sevenSectionTitle.value;
    const sevenSectionImage = e.target.sevenSectionImage.value;
    const time = e.target.time.value;
    const siteLink = e.target.siteLink.value;
    const ViewAllProjects = "View All Projects"

    const getData = {
      category: category,
      projectTitle: projectTitle,
      displayTitle: displayTitle,
      displayDiscreption: displayDiscreption,
      displayImage: displayImage,
      SecondSecTitle: SecondSecTitle,
      secondSecFirstLi: secondSecFirstLi,
      secondSecSecondLi: secondSecSecondLi,
      secondSecImage: secondSecImage,
      thirdSecTitle: thirdSecTitle,
      thirdSecFirstLi: thirdSecFirstLi,
      thirdSecSecondLi: thirdSecSecondLi,
      thirdSecImage: thirdSecImage,
      forthSecFirstImage: forthSecFirstImage,
      forthSecSecondImage: forthSecSecondImage,
      forthSecThirdImage: forthSecThirdImage,
      forthSecVideoUrl: forthSecVideoUrl,
      forthSecForthImage: forthSecForthImage,
      forthSecFifthImage: forthSecFifthImage,
      FifthSecTitle: FifthSecTitle,
      fifthSecDescription: fifthSecDescription,
      fifthSecImage: fifthSecImage,
      sixSecVideoUrl: sixSecVideoUrl,
      sixSecFirstImage: sixSecFirstImage,
      sixSecSecondImage: sixSecSecondImage,
      sixSecThirdImage: sixSecThirdImage,
      sixSecForthImage: sixSecForthImage,
      sixSecFifthImage: sixSecFifthImage,
      sevenSectionTitle: sevenSectionTitle,
      sevenSectionImage: sevenSectionImage,
      time: time,
      siteLink: siteLink,
      ViewAllProjects: ViewAllProjects
    }

    fetch(`https://briten-server.vercel.app/sixSecAllProjectData`, {
      method: 'POST',
      body: JSON.stringify(getData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => {
        console.log(response)
        if (response.ok) {
          setSuccessText("Updated Your Data")
          e.target.reset();
          refetch();
        }
      })
      .then((json) => console.log(json));

  }

  return (
    <div className=" portfolio-main-div  px-0 md:px-10 lg:px-14 max-w-[1440px] mx-auto ">
      <div className="hidden lg:block">
        <Folower></Folower>
      </div>
      <CustomCursor imagePath={cursorImg} visible={customCursor} />
      <NavBar></NavBar>

      <div className="my-title lg:text-left flex justify-between mx-5 lg:mx-14 items-center mt-20">

        {
          user?.email === adminEmail &&
          <button onClick={() => document.getElementById('updateProject').showModal()} className=" update-button" >Add Project</button>
        }
        <dialog id="updateProject" className="modal">

          <div className="modal-box w-11/12  max-w-5xl bg-sky-300">

            <form onSubmit={fourthSectionSubFun} className=' font-mono text-sm mt-14' >
              <input className='w-full rounded-md mt-2 p-2' name='category' type="text" placeholder='Category' />
              <input className='w-full rounded-md mt-2 p-2' name='projectTitle' type="text" placeholder='Project Name' />
              <input className='w-full rounded-md mt-2 p-2' name='displayTitle' type="text" placeholder='Heading ' />
              <textarea className='w-full rounded-md mt-2 p-2' name='displayDiscreption' type="text" placeholder='Description' />
              <input className='w-full rounded-md mt-2 p-2' name='displayImage' type="text" placeholder='Banner Photo Url' />
              <input className='w-full rounded-md mt-2 p-2' name='SecondSecTitle' type="text" placeholder='Second Section Title' />
              <textarea className='w-full rounded-md mt-2 p-2' name='secondSecFirstLi' type="text" placeholder='Second Section First Paragraph ' />
              <textarea className='w-full rounded-md mt-2 p-2' name='secondSecSecondLi' type="text" placeholder='Second Section Second Paragraph ' />
              <input className='w-full rounded-md mt-2 p-2' name='secondSecImage' type="text" placeholder='Second Section photo Url' />
              <input className='w-full rounded-md mt-2 p-2' name='thirdSecTitle' type="text" placeholder='Third Section Title' />
              <textarea className='w-full rounded-md mt-2 p-2' name='thirdSecFirstLi' type="text" placeholder='Third Section First Paragraph' />
              <textarea className='w-full rounded-md mt-2 p-2' name='thirdSecSecondLi' type="text" placeholder='Third Section Second Paragraph' />
              <input className='w-full rounded-md mt-2 p-2' name='thirdSecImage' type="text" placeholder='Third Section Photo Url' />
              <input className='w-full rounded-md mt-2 p-2' name='forthSecFirstImage' type="text" placeholder='Forth Section First photo Url' />
              <input className='w-full rounded-md mt-2 p-2' name='forthSecSecondImage' type="text" placeholder='Forth Section Second photo Url' />
              <input className='w-full rounded-md mt-2 p-2' name='forthSecThirdImage' type="text" placeholder='Forth Secction Third photo Url' />
              <input className='w-full rounded-md mt-2 p-2' name='forthSecVideoUrl' type="text" placeholder='Forth Section Video Url' />
              <input className='w-full rounded-md mt-2 p-2' name='forthSecForthImage' type="text" placeholder='Forth Section Forth photo Url' />
              <input className='w-full rounded-md mt-2 p-2' name='forthSecFifthImage' type="text" placeholder='Forth Section Fifth photo Url' />
              <input className='w-full rounded-md mt-2 p-2' name='FifthSecTitle' type="text" placeholder='Fifth Section Title' />
              <textarea className='w-full rounded-md mt-2 p-2' name='fifthSecDescription' type="text" placeholder='Fifth Section Description' />
              <input className='w-full rounded-md mt-2 p-2' name='fifthSecImage' type="text" placeholder='Fifth Section photo Url' />
              <input className='w-full rounded-md mt-2 p-2' name='sixSecVideoUrl' type="text" placeholder='six Section Video Url' />
              <input className='w-full rounded-md mt-2 p-2' name='sixSecFirstImage' type="text" placeholder='six Section First photo Url' />
              <input className='w-full rounded-md mt-2 p-2' name='sixSecSecondImage' type="text" placeholder='six Section Second photo Url' />
              <input className='w-full rounded-md mt-2 p-2' name='sixSecThirdImage' type="text" placeholder='six Section Third photo Url' />
              <input className='w-full rounded-md mt-3 p-2' name='sixSecForthImage' type="text" placeholder='six Section Forth photo Url' />
              <input className='w-full rounded-md mt-3 p-2' name='sixSecFifthImage' type="text" placeholder='six Section Fifth photo Url' />
              <input className='w-full rounded-md mt-3 p-2' name='sevenSectionTitle' type="text" placeholder='Seven Section Title' />
              <input className='w-full rounded-md mt-3 p-2' name='sevenSectionImage' type="text" placeholder='seven Section Photo Url ' />
              <input className='w-full rounded-md mt-3 p-2' name='time' type="text" placeholder='set Time' />
              <input className='w-full rounded-md mt-3 p-2' name='siteLink' type="text" placeholder=' Project Live Link ' />



              <input className='w-full mt-2 h-12 bg-gray-300 text-xl font-semibold rounded-md hover:text-red-500 hover:text-2xl duration-500 cursor-pointer' type="submit" value="submit" />
            </form>
            {
              successText && <h2 className="text-center text-xl "> {successText} </h2>
            }

            <div className="modal-action absolute top-2 right-2 ">
              <form method="dialog">
                <button className="btn">X</button>
              </form>
            </div>
          </div>

        </dialog>

      </div>

      <section className="lg:flex mx-5 lg:mx-14">

        <section className="  lg:w-[35%]  section"  >

          <div
            className="  "

          >
            <h2 class="font-NewYork text-[40px] md:text-[56px]  leading-[56px] tracking-normal text-center lg:text-left">Our Projects</h2>


            <div className=" gap-6 text-xl font-sans flex flex-wrap mt-[24px] md:mt-[32px] lg:mt-[48px]">
              <button onClick={() => clearAllFun()} className={` my-btn font-[Inter] text-[#131519]
                        ${allDesign.length === 0 ? "bg-gray-900 text-white" : ""}
            `}>All</button>
              <button onClick={() => setulUxDesign("UI/UX Design")} className={` my-btn font-[Inter] text-[#131519]
                        ${ulUxDesign.length > 0 ? "bg-gray-900 text-white" : ""}
            `}>UI/UX Design</button>
              <button onClick={() => setWebsite("Website")} className={` my-btn font-[Inter] text-[#131519]
                        ${Website.length > 0 ? "bg-gray-900 text-white" : ""}
            `}>Website</button>
              <button onClick={() => setApps("Apps")} className={` my-btn font-[Inter] text-[#131519] 
                        ${apps.length > 0 ? "bg-gray-900 text-white" : ""}
            `}>Apps</button>
              <button onClick={() => setBranding("Branding")} className={` my-btn font-[Inter] text-[#131519] 
                        ${branding.length > 0 ? "bg-gray-900 text-white" : ""}
            `}>Branding</button>
            </div>

          </div>
        </section>

        <section className="data-section mt-10 lg:w-[65%] lg:mt-8">
          {
            isLoading ? <div className='text-center h-[50vh] text-4xl flex justify-center items-center'>
              <img className='h-[150px] w-[150px] rounded-full' src="https://i.ibb.co/4dGgQdj/loading.gif" alt="" /> </div>
              :
              <div>
                {
                  PortfolioData.length === 0 ? <div className="h-[50vh] flex justify-center items-center flex-col">
                    <h2 className="text-red-500 text-3xl">Project Not Available At This Moment</h2>
                  </div>
                    :
                    <div
                      onMouseOver={() => { setCustomCursor('block') }}
                      onMouseLeave={() => { setCustomCursor('none') }}
                      className="font-sans">
                      {PortfolioData?.slice(0, visible).map((project) => {
                        return (
                          <Link to={`/CaseStudy/${project._id}`}>
                            <div
                              onMouseEnter={() => setCursorImg(project.displayImage)}
                              className="border-y py-6 px-4 flex justify-between  items-center group hover:bg-[#F1F1F1]">

                              <div className=" lg:group-hover:ml-5 ">
                                <div className="text-[28px] p-2 font-[Inter]">{project.displayTitle}</div>
                                <div className="block lg:hidden group-hover:block text-sm p-2">{project.time}</div>
                              </div>

                              <div className="my-auto lg:hidden group-hover:block">
                              </div>

                              <div className=" block lg:hidden group-hover:block">
                                <PiArrowBendDoubleUpRightBold></PiArrowBendDoubleUpRightBold>
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                    </div>

                }
              </div>
          }




          {
            sixSecAllProjectData?.length < visible ? "" :
              <div className="mt-10 text-center font-sans">
                <button
                  className="my-btn text-black flex gap-2 mx-auto"
                  onClick={showMore}
                >
                  <img className="my-auto" src={Reload} alt="" />
                  Load more
                </button>
              </div>

          }



        </section>

      </section>

      <section className="section">
        <Lets></Lets>
      </section>

      <Footer></Footer>
      <Link to="/contact">
        <div className={`flex flex-row justify-content-end align-items-center cursor-pointer contact-us-btn`}>
          <img
            src="https://i.ibb.co/S0sQyrS/contactus.png"
            alt="CONTACT us"
            className="contact_png"
          />
          <img src='https://i.ibb.co/TvnHdRH/white-Chats-Circle.png' className='contact-us-chat-icon' />
        </div>
      </Link >



    </div>
  );
};

export default Portfolio;




