import React, { useState } from 'react';

const Folower = ({folowerVisible}) => {
  
  const [mousex, setMousex] = useState();
  const [mousey, setMouseY] = useState();
  const rect = document.body.getBoundingClientRect();
  window.addEventListener('mousemove', setMousePosition);
  function setMousePosition(e) {
    setMousex(e.clientX - rect.left);
    setMouseY(e.clientY - rect.top);
  }

  return (
    <div   onMouseMove={setMousePosition} >
            <div
            style={{
              position: "absolute",
              width: "11px",
              height: "11px",
              backgroundColor : "black",
              mixBlendMode: "multiply",
              backgroundSize: "cover",
              pointerEvents: "none",
              zIndex: 2,
              display: `${folowerVisible}`,
              transform: "translate(-50%, -50%)",
              left: `${mousex}px`,
              top: `${mousey}px`,
              borderRadius : "50%"
            }}

            class="custom-cursor"
          >
          </div>
    </div>
  );
};

export default Folower;