import React, { useEffect, useState } from 'react';

const NavFolower = ({ linkFolower }) => {
  const [mousex, setMousex] = useState();
  const [mousey, setMouseY] = useState();
  const [displayValue, setDisplayValue] = useState(0);
  const [showFolower, setShowFolower] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const y = window.innerWidth;
      setDisplayValue(y);
      
      if (displayValue < 1000) {
        setShowFolower(false)
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const rect = document.body.getBoundingClientRect();
  window.addEventListener('mousemove', setMousePosition);
  function setMousePosition(e) {
    setMousex((e.clientX - rect.left) - 65);
    setMouseY((e.clientY - rect.top - 15));

    // if (displayValue < 1000) {
    //   setShowFolower(false)
    // }

    // if(displayValue <= 768){
    //   setMousex((e.clientX - rect.left) - 45);
    //   setMouseY((e.clientY - rect.top - 80));
    // }
    // else if(displayValue <= 1100){
    //   setMousex((e.clientX - rect.left) - 50);
    // }
    // else if(displayValue <= 900){
    //   setMousex((e.clientX - rect.left) - 70);
    // }
    // else if(displayValue <= 501){
    //   setMousex((e.clientX - rect.left)-800);
    //   setMouseY((e.clientY - rect.top));
    // }
    // else{
    //   setMousex((e.clientX - rect.left) - 65);
    //   setMouseY((e.clientY - rect.top - 5));
    // }

  }

  return (
    <div onMouseMove={setMousePosition} >
      <div
        style={{
          position: "absolute",
          width: "80px",
          height: "80px",
          backgroundColor: "black",
          mixBlendMode: "multiply",
          backgroundSize: "cover",
          pointerEvents: "none",
          zIndex: -1,
          display: `${showFolower ? "none" : linkFolower}`,
          // display: "none",
          // display: `${linkFolower}`,
          transform: "translate(-50%, -50%)",
          left: `${mousex}px`,
          top: `${mousey}px`,
          borderRadius: "50%"
        }}

        class="custom-cursor"
      >
      </div>
    </div>
  );
};

export default NavFolower;



