import { useQuery } from '@tanstack/react-query';

const useHomePageFourthSect = () => {
    const { data: homePageFourthSectionData, refetch : Hforthrefetch , isLoading} = useQuery({
        queryKey: ['homePageFourthSectionData'],
        queryFn: async () => {
            const res = await fetch(`https://briten-server.vercel.app/homePageFourthSectionData`);
            const data = await res.json();
            return data[0];
        },
    });
    return [homePageFourthSectionData, Hforthrefetch, isLoading];
};
export default useHomePageFourthSect;