import Hero from './components/Home';
const Home = () => {
  return (
    <>
      <Hero />
    </>
  );
};

export default Home;
