import React from 'react';
import '../style/homeEightSec.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../style/homeEightSec.css'

const EightSec = () => {
	
	return (
		<>
			<div className="lg:mt-[150px] lg:pt-[90px] -mt-20 md:mt-0 ">

				<div className='block lg:hidden w-11/12 mx-auto'>

						<div className=' text-center '>
							<h2 className="mt-[36px] text-[40px] md:text-[56px] lg:text-[65px] leading-[70px] font-[400]">
								We believe in improving life's
							</h2>

							<p className="mt-10 font-[Inter] md:text-[28px] leading-[40px] ">
								We are committed to giving back to the <br /> community and donate
								5% of our revenue to <br />
								charities that make a difference in the lives of <br />
								others.
							</p>
						</div>
						
						<div className="mx-auto">
						<Swiper
								spaceBetween={30}
								centeredSlides={true}
								autoplay={{
								delay: 2500,
								disableOnInteraction: false,
								}}
								modules={[Autoplay]}
								className="mySwiper"
							>
								<SwiperSlide><img className='w-full' src="images/cards/chuttersnap.png" alt="Image description" /></SwiperSlide>
								<SwiperSlide><img className='w-full' src="images/cards/american-unsplash.png" alt="Image description" /></SwiperSlide>
								<SwiperSlide><img className='w-full' src="images/cards/noah-unsplash.png" alt="Image description" /></SwiperSlide>
								<SwiperSlide><img className='w-full' src="images/cards/larm-unsplash.png" alt="Image description" /></SwiperSlide>
						</Swiper>
						</div>
						
				</div>
				
				<div className="lg:w-[85%] wemakeWrap mx-auto flex justify-between ">					

					<ul id="cards">

						<li className="card" id="card_1">
							<div className="card__content ">

								<div>
									<h2 className="mt-[36px] text-[40px] md:text-[56px] lg:text-[65px] leading-[70px] font-[400]">
										We believe in improving life's
									</h2>

									<p className="mt-10 text-xl leading-[40px] font-[Inter] md:text-[28px] ">
										We are committed to giving back to the  community and donate
										5% of our revenue to 
										charities that make a difference in the lives of
										others.
									</p>
								</div>
								
								<figure>
									<img src="images/cards/chuttersnap.png" alt="Image description" />
								</figure>
							</div>
						</li>

						<li className="card " id="card_2">
							<div className="card__content">
								<figure>
									<img src="images/cards/american-unsplash.png" alt="Image description" />
								</figure>
							</div>
						</li>

						<li className="card" id="card_3">
							<div className="card__content">
								<figure>
									<img src="images/cards/noah-unsplash.png" alt="Image description" />
								</figure>
							</div>
						</li>

						<li className="card" id="card_4">
							<div className="card__content">
								<figure>
									<img src="images/cards/larm-unsplash.png" alt="Image description" />
								</figure>
							</div>
						</li>
						
					</ul>
				</div>
			</div>
		</>
	);
};

export default EightSec;